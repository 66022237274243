import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Blog = () => {
  return (
    <Layout>
      <SectionHeader headerText="Blog" />
    </Layout>
  )
}

export default Blog
